<template>
	<main class="main">
		<div class="personal">
			<div class="container personal-inner">
				<div class="row">
					<div class="personal-cnt col-md-4 offset-md-4">
						<a class="personal-logo personal-logo--mb" href="#">
							<img src="../../../../../shared/assets/styles/images/logo.svg" alt="logo">

						</a>

						<validation-observer ref="observer">
							<b-form class="personal-form" @submit.stop.prevent="login">
								<ValidationProvider name="Email" rules="required|email" vid="email">

									<b-form-group label="Email"
												  label-for="email"
												  slot-scope="{ errors }">
										<b-form-input
												id="email"
												name="email"
												autocomplete="on"
												v-model="form.email"
												:state="errors[0] ? false : null"
												placeholder="Email"
												@input="dismissError">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>

								<ValidationProvider rules="required" name="Password" vid="password">
									<b-form-group label-for="password"
												  slot-scope="{ errors }"
												  label="Password">
										<b-form-input
												type="password"
												id="password"
												name="password"
												autocomplete="on"
												v-model="form.password"
												:state="errors[0] ? false : null"
												placeholder="Password"
												@input="dismissError">
										</b-form-input>
										<span class="pass-btn js-pass-btn"
											  @click="switchVisibility($event, 'password')"></span>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>

								<div class="form-group personal-form-group">
									<div class="form-check custom-control custom-checkbox">
										<div class="form-check custom-control custom-checkbox">
											<input class="form-check-input custom-control-input" id="rememberMe"
												   type="checkbox" v-model="form.remember_me">
											<label class="form-check-label custom-control-label" for="rememberMe"><span>Remember me</span></label>
										</div>
									</div>
									<router-link tag="a" class="personal-form-group__link"
												 :to="{name: 'app.forgot-password'}">Forgot password?
									</router-link>
								</div>
								<div v-if="error" class="invalid_feedback">
									{{error}}
								</div>
								<div class="form-group personal-form-group--mb">
									<input class="btn btn-primary personal-btn" type="submit" value="Sign in">
								</div>

								<p class="personal-links">Don’t have account yet?</p>
								<div class="personal-btns">
									<router-link tag="a" class="btn btn-outline-secondary" :to="{name: 'app.sign-up'}">
										Create Account
									</router-link>
								</div>
							</b-form>
						</validation-observer>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
    import {mapGetters} from "vuex";
    import {authHelperMixin} from "../../../../../shared/mixins/auth-mixin";

    export default {
        name: "LoginPage",
        mixins: [authHelperMixin],
        data() {
            return {
                form: {
                    email: "",
                    password: "",
                    remember_me: false,

                },
                error: false,

            }
        },
        methods: {
            async login() {

                this.error = false;
                let sendData = {
                    'email': this.form.email,
                    'password': this.form.password,
                    'remember_me': this.form.remember_me | 0
                };
                this.$refs.observer.validate().then(success => {
                    if (!success) {
                        return;
                    }
                    this.$store
                        .dispatch('auth/login', sendData)
                        .then(() => {
                            this.$router.push('/')
                        }).catch((err) => {
                        if (!err.response) {
                            this.showNotifications({message: 'Something went wrong!', type: 'danger'});
                        } else if (err.response.status === 422) {
                            this.error = err.response.data.errors.message[0];
                        }
                    })
                })
            },
            register() {
                this.$router.push('/sign-up')

            },
            dismissError() {
                this.error = false;
            },
        },
        computed: {
            ...mapGetters({
                user: "auth/getUser"
            })
        }
    }
</script>


<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/personal";

	.invalid_feedback {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
	}
</style>
